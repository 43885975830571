import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@esgian/esgianui';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment } from '@hooks';
import { useQuery } from '@tanstack/react-query';
import { getCanalDepartures } from '@api';
import { useParams } from 'react-router-dom';
import { DATE_FORMAT } from '@constants';
import OperatorTransitsChart from './OperatorTransitsChart/OperatorTransitsChart';

function CanalOperatorStatisticsSection() {
  const [init, setInit] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const { segment } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const params = useParams();
  const endDate = moment();
  let startDate = moment('2022-01-01');

  const handleSelectYear = useCallback(({ target }) => {
    setSelectedYear(target.value);
  }, []);

  const years = useMemo(() => {
    let res = [];
    for (let i = startDate.year(); i <= endDate.year(); i++) {
      res.push(i);
    }
    return res;
  }, []);

  const canalDeparturesQuery = useQuery({
    queryKey: ['canal', 'departures', segment.id, operatorType, commercialCategoryType],
    enabled: !!startDate && !!endDate,
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      const body = {
        start: `${moment(startDate).format(DATE_FORMAT)}Z`,
        end: `${moment(endDate).add(1, 'day').format(DATE_FORMAT)}Z`,
        operatorTypeId: queryKey[3],
        segmentTypeId: queryKey[2],
        commercialCategoryId: queryKey[4],
        canalIds: [params.canalId]
      };
      return getCanalDepartures(body, signal)
        .then((results) => results)
        .catch(() => []);
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, height: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <Typography variant={'h6'}>Top Operators and Share of Port Calls</Typography>
            </Grid>
            <Grid item xs={1}>
              <FormControl sx={{ minWidth: 120 }} size="small">
                <InputLabel id="top-port-year-label">Year</InputLabel>
                <Select
                  id="top-port-year-select"
                  value={selectedYear}
                  label="Year"
                  onChange={handleSelectYear}>
                  {years?.map((year) => (
                    <MenuItem key={`select-${year}`} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <OperatorTransitsChart
                canalTransits={canalDeparturesQuery.data}
                loading={canalDeparturesQuery.isFetching}
                fileName={'tbc'}
                startDate={moment(selectedYear + '-01-01')}
                endDate={moment(selectedYear + '-' + moment().month() + '-' + moment().day())}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid item xs={12} sx={{ height: '100%' }}>
        <PortOperatorStatsTable
          selectedYear={selectedYear}
          operatorStats={operatorStats}
          loading={loading}
          fileName={`operator-stats-${portName?.toLowerCase().split(' ').join('-')}`}
        />
      </Grid> */}
    </Grid>
  );
}

CanalOperatorStatisticsSection.propTypes = {
  loading: PropTypes.bool,
  portName: PropTypes.string,
  operatorStats: PropTypes.arrayOf(PropTypes.object),
  selectedYear: PropTypes.number.isRequired
};

CanalOperatorStatisticsSection.defaultProps = {
  loading: false,
  portName: '',
  operatorStats: null
};

export default CanalOperatorStatisticsSection;
