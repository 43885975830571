import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Grid } from '@esgian/esgianui';
import moment from 'moment';
import PortCallShareChart from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortOperatorStatisticsSection/PortCallShareChart';

function OperatorTransitsChart({
  loading = false,
  fileName = '',
  canalTransits = null,
  startDate = null,
  endDate = null
}) {
  let operatorTotals = { prevYearYTDPortCalls: 0, ytdPortCalls: 0, difference: 0 };

  console.log(startDate);

  //calculating the initial numbers
  const operatorChartData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let tempDataSet = [];
    let mainDataSet = [];
    canalTransits?.forEach(({ operatorId, operatorShortName, canalArrival }) => {
      if (
        !tempDataSet.find((operator) => {
          return operator.operatorId === operatorId;
        })
      ) {
        tempDataSet.push({
          difference: 0,
          differenceShare: 0,
          increase: 0,
          increaseShare: 0,
          operatorId: operatorId,
          operatorShortName: operatorShortName,
          prevYearYTDPortCalls: 0,
          prevYearYTDPortCallsShare: 0,
          ytdPortCalls: 0,
          ytdPortCallsShare: 0
        });
      }
      //calculating YTD numbers
      let arrival = moment(canalArrival);
      if (arrival.isBetween(startDate, endDate)) {
        let currentVal = tempDataSet.find((operator) => operator.operatorId === operatorId);
        currentVal.ytdPortCalls++;
      }
      //calulating previous YTD numbers
      if (
        arrival.isBetween(
          startDate.clone().subtract(1, 'year'),
          endDate.clone().subtract(1, 'year')
        )
      ) {
        let currentVal = tempDataSet.find((operator) => operator.operatorId === operatorId);
        currentVal.prevYearYTDPortCalls++;
      }
      //calculating the diff between current YTD and previous YTD
      let currentVal = tempDataSet.find((operator) => operator.operatorId === operatorId);
      if (currentVal.prevYearYTDPortCalls !== 0) {
        currentVal.difference = currentVal.ytdPortCalls - currentVal.prevYearYTDPortCalls;
      }
      //calculating the increase between previous YTD and current YTD
      let tempIncrease = currentVal.ytdPortCalls * 100;
      if (currentVal.prevYearYTDPortCalls !== 0) {
        tempIncrease = (tempIncrease / currentVal.prevYearYTDPortCalls).toFixed(2);
      }
      currentVal.increase =
        parseInt(tempIncrease) === parseFloat(tempIncrease)
          ? (100 - tempIncrease).toFixed(0)
          : (100 - tempIncrease).toFixed(2);
    });
    tempDataSet.forEach((operator) => {
      if (operator.ytdPortCalls !== 0 || operator.prevYearYTDPortCalls !== 0) {
        mainDataSet.push(operator);
      }
    });
    return mainDataSet;
  }, [canalTransits, startDate, endDate]);

  console.log(canalTransits, 'canalTransits');

  operatorChartData.forEach((operator) => {
    operatorTotals['prevYearYTDPortCalls'] += operator.prevYearYTDPortCalls;
    operatorTotals['ytdPortCalls'] += operator.ytdPortCalls;
    operatorTotals['difference'] += operator.difference;
  });
  //calculating the shares
  operatorChartData.forEach((operator) => {
    const tempYTDCalls = (operator.ytdPortCalls * 100) / operatorTotals['ytdPortCalls'];
    operator.ytdPortCallsShare =
      parseInt(tempYTDCalls) === parseFloat(tempYTDCalls)
        ? tempYTDCalls.toFixed(0)
        : tempYTDCalls.toFixed(2);

    const tempPrevCalls =
      (operator.prevYearYTDPortCalls * 100) / operatorTotals['prevYearYTDPortCalls'];
    operator.prevYearYTDPortCallsShare =
      parseInt(tempPrevCalls) === parseFloat(tempPrevCalls)
        ? tempPrevCalls.toFixed(0)
        : tempPrevCalls.toFixed(2);

    const tempDifference = (operator.difference * 100) / operatorTotals['difference'];
    operator.differenceShare =
      parseInt(tempDifference) === parseFloat(tempDifference)
        ? tempDifference.toFixed(0)
        : tempDifference.toFixed(2);

    let tempIncrease = parseFloat(operator.ytdPortCallsShare) * 100;
    if (operator.prevYearYTDPortCalls !== 0) {
      tempIncrease = (tempIncrease / parseFloat(operator.prevYearYTDPortCallsShare)).toFixed(2);
    }
    operator.increaseShare =
      parseInt(tempIncrease) === parseFloat(tempIncrease)
        ? (100 - tempIncrease).toFixed(0)
        : (100 - tempIncrease).toFixed(2);
  });

  let yearGroups = [];
  operatorChartData.forEach(({ year }) => {
    if (!yearGroups.find(({ mentionedYear }) => mentionedYear === year)) {
      yearGroups.push({
        mentionedYear: year,
        yearCount: 1
      });
    } else {
      yearGroups.find(({ mentionedYear }) => mentionedYear === year).yearCount++;
    }
  });

  const sortedOpsPrevious = [...operatorChartData].sort((firstOp, secondOp) => {
    if (Number(firstOp.prevYearYTDPortCallsShare) < Number(secondOp.prevYearYTDPortCallsShare)) {
      return 1;
    }
    if (Number(firstOp.prevYearYTDPortCallsShare) > Number(secondOp.prevYearYTDPortCallsShare)) {
      return -1;
    }
    return 0;
  });

  const sortedOpsCurrent = [...operatorChartData].sort((firstOp, secondOp) => {
    if (Number(firstOp.ytdPortCallsShare) < Number(secondOp.ytdPortCallsShare)) {
      return 1;
    }
    if (Number(firstOp.ytdPortCallsShare) > Number(secondOp.ytdPortCallsShare)) {
      return -1;
    }
    return 0;
  });

  let chartOpsPrevious = [];
  let chartOpsCurrent = [];

  sortedOpsPrevious.forEach((operator) => {
    if (sortedOpsPrevious.indexOf(operator) <= 10) {
      chartOpsPrevious.push(operator);
      //   chartOpsCurrent.push(operator);
    }
    if (sortedOpsPrevious.indexOf(operator) >= 10) {
      chartOpsPrevious[10].prevYearYTDPortCallsShare =
        Number(chartOpsPrevious[10].prevYearYTDPortCallsShare) +
        Number(operator.prevYearYTDPortCallsShare);
      //   chartOpsCurrent[10].ytdPortCallsShare += sortedOpsPrevious[index].ytdPortCallsShare;
    }
  });

  sortedOpsCurrent.forEach((operator) => {
    if (sortedOpsCurrent.indexOf(operator) <= 10) {
      chartOpsCurrent.push(operator);
    }
    if (sortedOpsCurrent.indexOf(operator) >= 10) {
      chartOpsPrevious[10].ytdPortCallsShare =
        Number(chartOpsCurrent[10].ytdPortCallsShare) + Number(operator.ytdPortCallsShare);
    }
  });

  if (chartOpsPrevious[10]) {
    chartOpsPrevious[10].operatorShortName = 'Others';
    chartOpsPrevious[10].operatorId = 0;
  }

  if (chartOpsCurrent[10]) {
    chartOpsCurrent[10].operatorShortName = 'Others';
    chartOpsCurrent[10].operatorId = 0;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant={'h6'}>Previous YTD data</Typography>
          <PortCallShareChart
            categories={[
              ...chartOpsPrevious.map(({ operatorShortName }) => {
                return operatorShortName;
              })
            ]}
            seriesData={[
              ...chartOpsPrevious.map(({ prevYearYTDPortCallsShare }) => {
                return Number(prevYearYTDPortCallsShare);
              })
            ]}
            selectedYear={startDate.year()}
            loading={loading}
            //   fileName={`operator-stats-${portName?.toLowerCase().split(' ').join('-')}`}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'h6'}>Current YTD data</Typography>
          <PortCallShareChart
            categories={[
              ...chartOpsCurrent.map(({ operatorShortName }) => {
                return operatorShortName;
              })
            ]}
            seriesData={[
              ...chartOpsCurrent.map(({ ytdPortCallsShare }) => {
                return Number(ytdPortCallsShare);
              })
            ]}
            selectedYear={startDate.year()}
            loading={loading}
            //   fileName={`operator-stats-${portName?.toLowerCase().split(' ').join('-')}`}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

OperatorTransitsChart.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string,
  canalTransits: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default OperatorTransitsChart;
