import { BaseApiRequest } from '@api/BaseApi';

export const getCanalDepartures = (data, signal) => {
  return BaseApiRequest(signal, `/Canal/Departures`, 'ships', 'Post', data);
};
export const getCanalArrivals = (data, signal) => {
  return BaseApiRequest(signal, `/Canal/ExpectedArrivals`, 'ships', 'Post', data);
};

export const getCanalInTransit = (data, signal) => {
  return BaseApiRequest(signal, `/Canal/InTransit`, 'ships', 'Post', data);
};

export const getCanalOverview = (data, signal) => {
  return BaseApiRequest(signal, `/RegionData`, 'geo', 'Post', data);
};
