import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, NavigationControl } from 'mapbox-gl';
import { MAP_STYLE_SATELLITE_MODE } from '@constants';
import { CircularProgress, Stack, Typography } from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import useMapFullscreen from '@hooks/useMapFullscreen';
import MapFullscreenButton, {
  mapFullscreenButtonParentStyle
} from '@components/Common/MapFullscreenButton';

const createMarker = (map, terminals, cpointlon, cpointlat) => {
  if (map.getLayer('circle')) {
    map.removeLayer('circle');
  }
  if (map.getSource('waypoints')) {
    map.removeSource('waypoints');
  }
  let center = [];
  let boundsSet = false;
  if (!cpointlon !== null && cpointlat !== null) {
    center.push(cpointlon, cpointlat);
    boundsSet = true;
  }

  let ret = [];
  terminals.forEach(({ terminalLat, terminalLong, radius, id }) => {
    if (!boundsSet && terminalLat !== null && terminalLong !== null) {
      center.push(terminalLong, terminalLat);
      boundsSet = true;
    }
    const markerEl = document.createElement('div');
    markerEl.className = 'pin-terminal';
    markerEl.style.backgroundImage = "url('/assets/images/blue-pin.svg')";
    markerEl.style.width = '2em';
    markerEl.style.height = '2.5em';
    const marker = new Marker(markerEl).setLngLat([terminalLong, terminalLat]).addTo(map);
    //Adjust zoom level based on number of terminals
    const zoom = terminals.length ? terminals.length * 0.4 : 0;

    map.flyTo({ center: center, zoom: 13 - zoom, duration: 0 });

    const markerDiv = marker.getElement();
    markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
    markerDiv.addEventListener('mouseleave', () => marker.togglePopup());
    ret.push({
      type: 'Feature',
      properties: {
        id: 1,
        name: `terminal-${id}`,
        radius: radius || 0
      },
      geometry: {
        type: 'Point',
        coordinates: [terminalLong, terminalLat]
      }
    });
  });

  map.addSource('waypoints', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: ret
    }
  });
  map.addLayer({
    id: 'circle',
    type: 'circle',
    source: 'waypoints',
    paint: {
      'circle-radius': [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        0,
        0,
        22,
        ['/', ['/', ['get', 'radius'], 0.01866137981], ['cos', ['*', 44, ['/', ['pi'], 180]]]]
      ],
      'circle-color': 'rgba(217,217,217,0.5)',
      'circle-opacity': 0.6,
      'circle-stroke-width': 1,
      'circle-stroke-color': 'rgb(184,232,241)'
    }
  });
};
function PortMap({ portData, loading, height }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const themeMode = useSelector(getThemeMode);
  const { isFullscreen, toggleFullscreen } = useMapFullscreen();

  useEffect(() => {
    if (!portData) return; // initialize map only once
    const { cpointlat, cpointlon, terminals } = portData;

    map.current = new Map({
      container: mapContainer.current,
      style: MAP_STYLE_SATELLITE_MODE,
      projection: { name: 'mercator' },
      center: [cpointlon, cpointlat],
      zoom: 10
    });
    map.current.addControl(new NavigationControl());
    map.current.on('load', () => {
      if (map.current) {
        createMarker(map.current, terminals, cpointlon, cpointlat);
      }
    });
    return () => {
      map.current = null;
    };
  }, [portData, isFullscreen]);

  const heightStyle = isFullscreen
    ? {
        height: '100vh',
        width: '100vw'
      }
    : {
        height: height,
        width: '100%'
      };
  return (
    <div
      ref={mapContainer}
      style={{
        borderRadius: '10px',
        ...heightStyle
      }}
      className="port-profile-map">
      {loading && (
        <div
          style={{
            height: '100%',
            width: '100%',
            background: 'rgb(255 255 255 / 50%)',
            zIndex: 2,
            position: 'absolute'
          }}>
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        </div>
      )}
      {isFullscreen && (
        <div
          id="vessels-map-legend"
          className="legend-vessels-map"
          style={{
            backgroundColor: themeMode ? 'rgba(11,26,46,0.8)' : 'rgba(255, 255, 255, 0.8)',
            position: 'absolute',
            left: '5px',
            top: '5px'
          }}>
          <Stack sx={{ pl: 2, pr: 4, pb: 2, pt: 2 }} spacing={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <span>
                <img src="/assets/images/blue-pin.svg" alt="Blue Pin" />
              </span>
              <Typography variant={'body2'}>{`Terminal`}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <span>
                <img src="/assets/images/terminal-range-icon.svg" alt="Terminal range" />
              </span>
              <Typography variant={'body2'}>{`Terminal range`}</Typography>
            </Stack>
          </Stack>
        </div>
      )}
      <div style={mapFullscreenButtonParentStyle}>
        <MapFullscreenButton
          onClick={() => toggleFullscreen(mapContainer.current)}
          isFullScreen={isFullscreen}
        />
      </div>
    </div>
  );
}

PortMap.propTypes = {
  portData: PropTypes.object,
  loading: PropTypes.bool,
  height: PropTypes.string
};

PortMap.defaultProps = {
  portData: null,
  loading: false,
  height: '40vh'
};

export default PortMap;
