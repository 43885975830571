import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel
} from '@esgian/esgianui';

function GroupSelect({ groupList, handleChange }) {
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleGroupChange = (event) => {
    const value = event.target.value;
    setSelectedGroups(value);
    handleChange(value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{'Groups'}</InputLabel>
      <Select
        multiple
        label={'Groups*'}
        value={selectedGroups}
        onChange={handleGroupChange}
        displayEmpty
        renderValue={(selected) => (selected.length === 0 ? '' : selected.join(', '))}>
        {groupList.map((group) => (
          <MenuItem key={group.value} value={group.label}>
            <Checkbox checked={selectedGroups.indexOf(group.label) > -1} />
            <ListItemText primary={group.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

GroupSelect.propTypes = {
  groupList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired
};

export default GroupSelect;
