import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import mapboxgl, { Map, NavigationControl } from 'mapbox-gl';
import { MAP_STYLE_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { Stack, Typography, Grid } from '@esgian/esgianui';
import VesselPositionDetails from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselOverviewSection/VesselPositionDetails/VesselPositionDetails';
import useMapFullscreen from '@hooks/useMapFullscreen';
import MapFullscreenButton, {
  mapFullscreenButtonParentStyle
} from '@components/Common/MapFullscreenButton';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const putMarkerOnMap = (map, pulsingDot, long, lat) => {
  if (!map.hasImage('pulsing-dot')) {
    map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
  }
  if (map.getLayer('layer-with-pulsing-dot')) {
    map.removeLayer('layer-with-pulsing-dot');
  }
  if (map.getSource('dot-point')) {
    map.removeSource('dot-point');
  }

  map.addSource('dot-point', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [long, lat] // icon position [lng, lat]
          }
        }
      ]
    }
  });

  map.addLayer({
    id: 'layer-with-pulsing-dot',
    type: 'symbol',
    source: 'dot-point',
    layout: {
      'icon-image': 'pulsing-dot'
    }
  });

  map.flyTo({ center: [long, lat], zoom: 2 });
};

const createMarker = (map, lat, long) => {
  const size = 100;

  // This implements `StyleImageInterface`
  // to draw a pulsing dot icon on the map.
  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    // When the layer is added to the map,
    // get the rendering context for the map canvas.
    onAdd: function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },
    // Call once before every frame where the icon will be used.
    render: function () {
      const duration = 1000;
      const t = (performance.now() % duration) / duration;
      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.7 * t + radius;
      const context = this.context;

      // Draw the outer circle.
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
      context.fill();

      // Draw the inner circle.
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = 'rgba(255, 100, 100, 1)';
      context.strokeStyle = 'white';
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      map.triggerRepaint();

      // Return `true` to let the map know that the image was updated.
      return true;
    }
  };
  const addMarker = () => {
    putMarkerOnMap(map, pulsingDot, long, lat);
  };

  const handleStyleload = () => {
    setTimeout(() => {
      addMarker();
    }, 200);
  };

  if (map.isStyleLoaded()) {
    setTimeout(() => {
      addMarker();
    }, 200);
  } else {
    map.once('styledata', handleStyleload);
  }

  map.on('load', () => {
    map.resize();
    if (map.isStyleLoaded()) {
      addMarker();
    }
  });
};

function VesselMap({ vesselLatestPositionData, loading }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const themeMode = useSelector(getThemeMode);
  const { isFullscreen, toggleFullscreen } = useMapFullscreen();

  useEffect(() => {
    map.current = new Map({
      container: mapContainer.current,
      style: themeMode ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [0, 0],
      height: '100%',
      zoom: 0
    });
    map.current.addControl(new NavigationControl());
  }, [isFullscreen]);

  useEffect(() => {
    if (!map.current || !vesselLatestPositionData) return;
    map.current.setStyle(themeMode ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE);
    map.current.once('styledata', () => {
      const { lat, long } = vesselLatestPositionData;
      createMarker(map.current, lat, long);
    });
  }, [themeMode, vesselLatestPositionData, isFullscreen]);

  useEffect(() => {
    if (!vesselLatestPositionData) return;

    const { lat, long } = vesselLatestPositionData;
    createMarker(map.current, lat, long);
  }, [vesselLatestPositionData, themeMode, isFullscreen]);

  const heightStyle = isFullscreen
    ? {
        height: '100vh',
        width: '100vw'
      }
    : {
        height: '100%',
        width: '100%'
      };

  return (
    <div
      ref={mapContainer}
      style={{
        flex: 1,
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
        ...heightStyle
      }}
      className="vessel-profile-map">
      {isFullscreen && (
        <div
          id="vessel-map-legend"
          className="legend-vessel-map"
          style={{
            backgroundColor: themeMode ? 'rgba(11,26,46,0.8)' : 'rgba(255, 255, 255, 0.8)',
            position: 'absolute',
            left: '5px',
            top: '5px',
            width: '20vw'
          }}>
          <Stack sx={{ pl: 2, pr: 4, pb: 2, pt: 2 }} spacing={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <span>
                <img
                  src="/assets/images/location-pin.png"
                  alt="vessel-position-icon"
                  style={{ paddingTop: '4px' }}
                />
              </span>
              <Typography variant={'body2'}>{`Vessel Position`}</Typography>
            </Stack>
          </Stack>
          <div>
            <Grid item xs={12}>
              <VesselPositionDetails
                loading={loading}
                vesselLatestPositionData={vesselLatestPositionData}
              />
            </Grid>
          </div>
        </div>
      )}
      <div style={mapFullscreenButtonParentStyle}>
        <MapFullscreenButton
          onClick={() => toggleFullscreen(mapContainer.current)}
          isFullScreen={isFullscreen}
        />
      </div>
    </div>
  );
}

VesselMap.propTypes = {
  vesselLatestPositionData: PropTypes.object,
  loading: PropTypes.bool
};

VesselMap.defaultProps = {
  vesselLatestPositionData: null,
  loading: false
};

export default VesselMap;
