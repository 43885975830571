import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import {
  BasicTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@esgian/esgianui';
import moment from 'moment';

function CanalTransitsTable({
  startDate = null,
  endDate = null,
  loading = false,
  canalTransits = null,
  timeFrame = 'weekly',
  fileName = ''
}) {
  const user = useSelector(getUser);

  const weeklyTableData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let startDateClone = startDate.clone();
    let endDateClone = endDate.clone();
    let dataSet = [];
    while (
      startDateClone &&
      endDateClone &&
      startDateClone.isSameOrBefore(endDateClone.endOf('isoWeek'))
    ) {
      let weekNumber = startDateClone.isoWeek();
      let yearNumber = startDateClone.isoWeekYear();
      let key = `${yearNumber}_${weekNumber}`;
      dataSet.push({
        year: yearNumber,
        week: weekNumber,
        value: 0,
        id: key
      });
      startDateClone.add(1, 'isoWeek');
    }

    canalTransits?.forEach(({ canalArrival }) => {
      let arrival = moment(canalArrival);
      let weekNumber = arrival.clone().isoWeek();
      let yearNumber = arrival.clone().isoWeekYear();
      let key = `${yearNumber}_${weekNumber}`;

      let currentVal = dataSet.find(({ id }) => id === key);
      currentVal.value++;
    });

    return dataSet;
  }, [canalTransits]);

  const monthlyTableData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let start = startDate.clone();
    let end = endDate.clone();
    let dataSet = [];
    while (start && end && start.isSameOrBefore(end)) {
      let monthNumber = start.month();
      let yearNumber = start.year();
      let key = `${yearNumber}_${monthNumber}`;
      dataSet.push({
        year: yearNumber,
        month: monthNumber,
        value: 0,
        id: key
      });
      start.add(1, 'month');
    }
    canalTransits?.forEach(({ canalArrival }) => {
      let arrival = moment(canalArrival);
      let monthNumber = arrival.clone().month();
      let yearNumber = arrival.clone().year();
      let key = `${yearNumber}_${monthNumber}`;
      let currentVal = dataSet.find(({ id }) => id === key);
      currentVal.value++;
    });
    return dataSet;
  }, [canalTransits]);

  let yearGroups = [];
  if (timeFrame === 'weekly') {
    weeklyTableData.map(({ year }) => {
      if (!yearGroups.find(({ mentionedYear }) => mentionedYear === year)) {
        yearGroups.push({
          mentionedYear: year,
          yearCount: 1
        });
      } else {
        yearGroups.find(({ mentionedYear }) => mentionedYear === year).yearCount++;
      }
    });
  } else {
    monthlyTableData.map(({ year }) => {
      if (!yearGroups.find(({ mentionedYear }) => mentionedYear === year)) {
        yearGroups.push({
          mentionedYear: year,
          yearCount: 1
        });
      } else {
        yearGroups.find(({ mentionedYear }) => mentionedYear === year).yearCount++;
      }
    });
  }

  let timeColumns = [];
  if (timeFrame === 'weekly') {
    timeColumns = weeklyTableData.map(({ week }) => 'Week ' + week);
  } else {
    timeColumns = monthlyTableData.map(
      ({ month, year }) => moment().month(month).format('MMM') + ' ' + year
    );
  }

  let timeValues = [];

  if (timeFrame === 'weekly') {
    timeValues = weeklyTableData.map(({ value, id }) => ({ value, id }));
  } else {
    timeValues = monthlyTableData.map(({ value, id }) => ({ value, id }));
  }

  const weeklyColumns = useMemo(() => {
    if (!weeklyTableData.length) return;

    return weeklyTableData.map(({ id, week, value }) => {
      return {
        name: id,
        label: 'Week ' + week,
        options: {
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: '200px', maxWidth: '200px', width: '200px' }
          }),
          customBodyRender: () => {
            return value || 0;
          }
        }
      };
    });
  }, [weeklyTableData]);

  const monthlyColumns = useMemo(() => {
    if (!monthlyTableData.length) return;

    return monthlyTableData.map(({ id, month, year, value }) => {
      return {
        name: id,
        label: moment().month(month).format('MMM') + ' ' + year,
        options: {
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: '200px', maxWidth: '200px', width: '200px' }
          }),
          customBodyRender: () => {
            return value || 0;
          }
        }
      };
    });
  }, [monthlyTableData]);

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      downloadOptions: {
        filename: fileName
      },
      setTableProps: () => ({
        id: timeFrame === 'weekly' ? 'weekly-canal-transits-table' : 'monthly-canal-transits-table'
      }),
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '20vh',
      tableBodyHeight: '20vh',
      rowsPerPageOptions: [10, 25, 50, 100]
    };
  }, [weeklyColumns, monthlyColumns]);

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <BasicTable stickyHeader aria-label="sticky table" options={options}>
        <TableHead>
          <TableRow>
            {[...yearGroups].map(({ mentionedYear, yearCount }) => (
              <TableCell key={mentionedYear} align="center" colSpan={yearCount}>
                {mentionedYear ?? ''}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            {[...timeColumns].map((val) => {
              return (
                <TableCell key={val} align="center" colSpan={1}>
                  {val}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {[...timeValues].map(({ value, id }) => (
              <TableCell colSpan={1} align="center" key={id}>
                {value}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </BasicTable>
    </TableContainer>
  );
}

CanalTransitsTable.propTypes = {
  canalTransits: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  timeFrame: PropTypes.string,
  fileName: PropTypes.string
};

export default CanalTransitsTable;
