import moment from 'moment/moment';

export const VoyageType = {
  COMPLETED: 'COMPLETED',
  ONGOING: 'ONGOING'
};

export const getVoyageLink = (uriExt, voyageDetails, voyageGroupType) => {
  const formattedDepartureDate = moment(voyageDetails.departureDate).format('YYYY-MM-DDTHH:mm:ss');
  const formattedArrivalDate =
    voyageGroupType === VoyageType.ONGOING
      ? null
      : moment(voyageDetails.arrivalDate).format('YYYY-MM-DDTHH:mm:ss');

  return `${uriExt}/${voyageGroupType === VoyageType.ONGOING ? 'ongoing' : 'completed'}-voyages/${
    voyageDetails.imo
  }?departureDate=${formattedDepartureDate}&arrivalDate=${formattedArrivalDate}&fromPortId=${
    voyageDetails.fromPortId
  }&toPortId=${voyageDetails.toPortId}`;
};

export const generateVoyageIdFromSavedVoyage = (voyage) =>
  `${voyage.imo}-${voyage.fromPortId}-${voyage.departureDate}`;

export const getTransitDays = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
};

export const getTimeDaysAgo = (numDays) =>
  moment().startOf('day').subtract(numDays, 'days').toISOString();
