import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@esgian/esgianui';
import PortsInRegionTable from '@components/Tables/PortsInRegionTable';
import { RegionMap } from '@components/Maps';
import { UpcomingVesselPortCallsTable, VesselPortCallsTable } from '@components/Tables';

function RegionOverviewSection({
  setRegionPortCallsData,
  regionPortCallsData,
  loading,
  regionName,
  regionPortsData,
  regionUpcomingPortCallsData,
  geoId
}) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <PortsInRegionTable
            loading={loading}
            regionName={regionName}
            regionPortsData={regionPortsData}
          />
        </Grid>
        <Grid item xs={5}>
          <Paper>
            <RegionMap height={'40vh'} loading={loading} portData={regionPortsData} />
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <VesselPortCallsTable
            setPortCallData={setRegionPortCallsData}
            loading={loading}
            portCalls={regionPortCallsData}
            assetLevel={'region'}
            name={regionName}
            geoId={geoId}
          />
        </Grid>
        <Grid item xs={12}>
          <UpcomingVesselPortCallsTable
            assetLevel={'region'}
            title={'Upcoming Vessels Port Calls'}
            upcomingPortCalls={regionUpcomingPortCallsData}
            loading={loading}
            name={regionName}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

RegionOverviewSection.propTypes = {
  regionPortCallsData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  regionName: PropTypes.string,
  setRegionPortCallsData: PropTypes.func.isRequired,
  regionPortsData: PropTypes.arrayOf(PropTypes.object),
  regionUpcomingPortCallsData: PropTypes.arrayOf(PropTypes.object),
  geoId: PropTypes.number
};

RegionOverviewSection.defaultProps = {
  loading: false,
  regionName: null,
  regionPortCallsData: [],
  geoId: null
};

export default RegionOverviewSection;
