import React from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Paper, Stack } from '@esgian/esgianui';
import moment from 'moment';
import { ProfileLink } from '@components/Links';
import {
  getDateBetweenFilterListOptions,
  getDateBetweenFilterOptions
} from '@components/Tables/helpers';
import { checkUserHasAccess, tableTitlePaddingLeft, getDirection } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { DATE_TIME_FORMAT } from '@constants';

const getTimeDisplay = (startDate, endDate, hoursOnly) => {
  const start = moment(startDate, DATE_TIME_FORMAT);
  const end = moment(endDate, DATE_TIME_FORMAT);

  // If either date is invalid, return an error message
  if (!start.isValid() || !end.isValid()) {
    return 'Invalid date';
  }

  let duration = moment.duration(end.diff(start));
  hoursOnly = duration.asHours();
  return `${hoursOnly.toFixed(2)} `;
};

function CanalVesselPortCallsTable({
  title = 'Recent Departues',
  loading = false,
  canalDeparturesData = [],
  canalName = '',
  hideColumns = false
}) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const allColumns = [
    {
      name: 'vesselName',
      label: 'Vessel',
      options: {
        filterType: 'multiselect',
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'vessel'} name={dataIndex} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        sort: true,
        filterType: 'multiselect'
      }
    },
    {
      name: 'operatorShortName',
      label: 'Operator',
      options: {
        sort: true,
        filterType: 'multiselect',
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'operator'} name={dataIndex} id={parseInt(rowData[3])} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'operatorId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'speed',
      label: 'Speed',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect'
      }
    },
    {
      name: 'heading',
      label: 'Heading',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect'
      }
    },
    {
      name: 'canalArrival',
      label: 'Arrival',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex) => {
          if (moment(dataIndex).isValid()) {
            return moment(dataIndex).utc().format(DATE_TIME_FORMAT);
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'canalDeparture',
      label: 'Departure',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex) => {
          if (moment(dataIndex).isValid()) {
            return moment(dataIndex).utc().format(DATE_TIME_FORMAT);
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'transitTime',
      label: 'Time in transit',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null
            ? getTimeDisplay(
                moment(rowData[4]).format(DATE_TIME_FORMAT),
                moment(rowData[5]).format(DATE_TIME_FORMAT)
              )
            : '--';
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'headingDirection',
      label: 'Direction',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex) => {
          return dataIndex !== null ? getDirection(dataIndex) : '--';
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'originPort',
      label: 'Origin',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'port'} name={dataIndex.portName} id={rowData[8].portId} />
          ) : (
            '--'
          );
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'originATD',
      label: 'Origin ATD',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          if (
            Object.hasOwn(canalDeparturesData[rowIndex.rowIndex], 'originPort') &&
            canalDeparturesData[rowIndex.rowIndex].originPort !== null &&
            Object.hasOwn(canalDeparturesData[rowIndex.rowIndex].originPort, 'startDate') &&
            moment(canalDeparturesData[rowIndex.rowIndex].originPort.startDate).isValid()
          ) {
            return moment(canalDeparturesData[rowIndex.rowIndex].originPort.startDate).format(
              'YYYY-MM-DD HH:mm:ss'
            );
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'Destination',
      label: 'Destination',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRender: (dataIndex, rowIndex) => {
          let destinationName = null;
          let destinationId = null;
          if (
            Object.hasOwn(canalDeparturesData[rowIndex.rowIndex], 'actualNextPort') &&
            canalDeparturesData[rowIndex.rowIndex].actualNextPort !== null
          ) {
            destinationName = canalDeparturesData[rowIndex.rowIndex].actualNextPort.portName;
            destinationId = canalDeparturesData[rowIndex.rowIndex].actualNextPort.portId;
          } else if (
            Object.hasOwn(canalDeparturesData[rowIndex.rowIndex], 'reportedNextPort') &&
            canalDeparturesData[rowIndex.rowIndex].reportedNextPort !== null
          ) {
            destinationName = canalDeparturesData[rowIndex.rowIndex].reportedNextPort.portName;
            destinationId = canalDeparturesData[rowIndex.rowIndex].reportedNextPort.portId;
          } else {
            destinationName =
              canalDeparturesData[rowIndex.rowIndex].destinationPort !== null
                ? canalDeparturesData[rowIndex.rowIndex].destinationPort.portName
                : canalDeparturesData[rowIndex.rowIndex].destination;
          }
          return dataIndex !== null && destinationName !== null && destinationId !== null ? (
            <ProfileLink profile={'port'} name={destinationName} id={destinationId} />
          ) : dataIndex !== null && destinationName !== null && destinationId === null ? (
            destinationName
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'eta',
      label: 'Destination ETA',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          let destinationDate = null;
          if (
            Object.hasOwn(canalDeparturesData[rowIndex.rowIndex], 'reportedNextPort') &&
            canalDeparturesData[rowIndex.rowIndex].reportedNextPort !== null
          ) {
            destinationDate = canalDeparturesData[rowIndex.rowIndex].reportedNextPort.startDate;
          }
          if (moment(destinationDate).isValid()) {
            return moment(destinationDate).format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'ata',
      label: 'Destination ATA',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          let destinationDate = null;
          if (
            Object.hasOwn(canalDeparturesData[rowIndex.rowIndex], 'actualNextPort') &&
            canalDeparturesData[rowIndex.rowIndex].actualNextPort !== null
          ) {
            destinationDate = canalDeparturesData[rowIndex.rowIndex].actualNextPort.startDate;
          }
          if (moment(destinationDate).isValid()) {
            return moment(destinationDate).format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },

    {
      name: 'portCode',
      label: 'Destination LOCODE',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        filterType: 'multiselect',
        customBodyRender: (dataIndex, rowIndex) => {
          let destinationCode = null;
          destinationCode =
            canalDeparturesData[rowIndex.rowIndex].destinationPort !== null
              ? canalDeparturesData[rowIndex.rowIndex].destinationPort.portCode
              : canalDeparturesData[rowIndex.rowIndex].destination;
          return destinationCode ? destinationCode : '--';
        }
      }
    },
    {
      name: 'Arrival',
      label: 'Canal Arrival',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          if (
            Object.hasOwn(canalDeparturesData[rowIndex.rowIndex], 'canalArrival') &&
            moment(canalDeparturesData[rowIndex.rowIndex].canalArrival).isValid()
          ) {
            return moment(canalDeparturesData[rowIndex.rowIndex].canalArrival)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'portId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'eta',
      label: 'ETA',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex) => {
          if (moment(dataIndex).isValid()) {
            return moment(dataIndex).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'timeUntilArrival',
      label: 'Time until arrival (hours)',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null
            ? getTimeDisplay(
                moment().format(DATE_TIME_FORMAT),
                moment(rowData[6]).format(DATE_TIME_FORMAT)
              )
            : '--';
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'Estimated Time of Arrival',
      label: 'Estimated Time of Arrival',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex) => {
          if (moment(dataIndex).isValid()) {
            return moment(dataIndex).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'positionReceived',
      label: 'Position received',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          if (
            Object.hasOwn(canalDeparturesData[rowIndex.rowIndex], 'positionReceived') &&
            moment(canalDeparturesData[rowIndex.rowIndex].positionReceived).isValid()
          ) {
            return moment(canalDeparturesData[rowIndex.rowIndex].positionReceived).format(
              'YYYY-MM-DD HH:mm:ss'
            );
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Position received'),
        filterOptions: getDateBetweenFilterOptions('Position received', 'YYYY-MM-DDTHH:mm:ss')
      }
    }
  ];
  const filteredColumns = allColumns.filter((column) => !hideColumns.includes(column.label));

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: title !== 'In Transit',
    download: checkUserHasAccess(user, true) && title !== 'In Transit',
    setTableProps: () => {
      return {
        id: 'vessel-port-calls-table'
      };
    },
    downloadOptions: {
      filename: `Upcoming-Port-Calls-${canalName}.csv`
    },
    print: false,
    viewColumns: false,
    pagination: title !== 'In Transit',
    toolbar: title !== 'In Transit',
    elevation: 0,
    tableBodyMaxHeight: title !== 'In Transit' ? '80vh' : '30vh',
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'endDate',
      direction: 'desc'
    }
  };

  return (
    <Paper id={'canal-vessel-portcalls-table'}>
      <Table
        customStyle={tableTitlePaddingLeft}
        loading={loading}
        mode={theme.mode}
        title={
          title !== 'In Transit' ? (
            <Stack sx={{ pb: 2, pt: 2 }}>
              <Typography id={'vessel-port-calls-title'} variant={'h6'}>
                {title}
              </Typography>
            </Stack>
          ) : null
        }
        data={canalDeparturesData?.length ? canalDeparturesData : []}
        columns={filteredColumns}
        options={options}
      />
    </Paper>
  );
}

CanalVesselPortCallsTable.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  canalName: PropTypes.string,
  assetLevel: PropTypes.oneOf(['canal']).isRequired,
  upcomingPortCalls: PropTypes.arrayOf(PropTypes.object),
  canalDeparturesData: PropTypes.arrayOf(PropTypes.object),
  hideColumns: PropTypes.arrayOf(PropTypes.string)
};

export default CanalVesselPortCallsTable;
