import React, { useEffect } from 'react';
import {
  ChartColumnIcon,
  SpeedometerIcon,
  DashboardIcon,
  InsightsOutlinedIcon,
  Typography,
  Box
} from '@esgian/esgianui';
import { SEGMENT } from '@constants';
import { closeAllSideMenuItems, setSideMenuItemOpen } from '@store/features';
const isProd = process.env.CURRENT_ENV === 'prod';

// Menu items for RORO
const getSaRoRo = (routeChange) => [
  {
    id: 'commercial-analytics-sub',
    key: 'commercial-analytics-sub',
    text: 'Commercial Analytics',
    path: '/roro/commercial-analytics',
    onClick: () => {
      routeChange('/roro/commercial-analytics');
    }
  },
  {
    id: 'operators-sub',
    key: 'operators',
    text: 'Operators',
    path: '/roro/commercial-analytics/operators',
    onClick: () => {
      routeChange('/roro/commercial-analytics/operators');
    }
  },
  {
    id: 'owners-sub',
    key: 'owner',
    text: 'Owners',
    path: '/roro/commercial-analytics/owners',
    onClick: () => {
      routeChange('/roro/commercial-analytics/owners');
    }
  },
  {
    id: 'vessels-sub',
    key: 'vessels',
    text: 'Vessels',
    path: '/roro/commercial-analytics/vessels',
    onClick: () => {
      routeChange('/roro/commercial-analytics/vessels');
    }
  },
  {
    id: 'ports-sub',
    key: 'ports',
    text: 'Ports',
    path: '/roro/commercial-analytics/ports',
    onClick: () => {
      routeChange('/roro/commercial-analytics/ports');
    }
  },
  {
    id: 'countries-sub',
    key: 'countries',
    text: 'Countries',
    path: '/roro/commercial-analytics/countries',
    onClick: () => {
      routeChange('/roro/commercial-analytics/countries');
    }
  },
  {
    id: 'regions-sub',
    key: 'regions',
    text: 'Regions',
    path: '/roro/commercial-analytics/regions',
    onClick: () => {
      routeChange('/roro/commercial-analytics/regions');
    }
  },
  ...(!isProd
    ? [
        {
          id: 'canals-sub',
          key: 'canals',
          text: 'Canals',
          path: '/roro/commercial-analytics/canals',
          onClick: () => {
            routeChange('/roro/commercial-analytics/canals');
          }
        }
      ]
    : [])
];
const getEmissions = (routeChange, uriExt) => [
  {
    id: 'operator-comp-sub',
    key: 'operator-comp-sub',
    text: 'Operator comparison',
    path: `/${uriExt}/emissions-analytics/operator-comparison`,
    onClick: () => {
      routeChange(`/${uriExt}/emissions-analytics/operator-comparison`);
    }
  },
  {
    id: 'vessel-comp-sub',
    key: 'vessel-comp-sub',
    text: 'Vessel comparison',
    path: `/${uriExt}/emissions-analytics/vessel-comparison`,
    onClick: () => {
      routeChange(`/${uriExt}/emissions-analytics/vessel-comparison`);
    }
  }
];

// Menu items for MPP
const getSaMpp = (routeChange) => [
  {
    id: 'commercial-analytics-sub',
    key: 'commercial-analytics-sub',
    text: 'Commercial Analytics',
    path: '/mpp/commercial-analytics',
    onClick: () => {
      routeChange('/mpp/commercial-analytics');
    }
  },
  {
    id: 'operators-sub',
    key: 'operators',
    text: 'Operators',
    path: '/mpp/commercial-analytics/operators',
    onClick: () => {
      routeChange('/mpp/commercial-analytics/operators');
    }
  },
  {
    id: 'vessels-sub',
    key: 'vessels',
    text: 'Vessels',
    path: '/mpp/commercial-analytics/vessels',
    onClick: () => {
      routeChange('/mpp/commercial-analytics/vessels');
    }
  },
  {
    id: 'ports-sub',
    key: 'ports',
    text: 'Ports',
    path: '/mpp/commercial-analytics/ports',
    onClick: () => {
      routeChange('/mpp/commercial-analytics/ports');
    }
  },
  {
    id: 'countries-sub',
    key: 'countries',
    text: 'Countries',
    path: '/mpp/commercial-analytics/countries',
    onClick: () => {
      routeChange('/mpp/commercial-analytics/countries');
    }
  },
  {
    id: 'regions-sub',
    key: 'regions',
    text: 'Regions',
    path: '/mpp/commercial-analytics/regions',
    onClick: () => {
      routeChange('/mpp/commercial-analytics/regions');
    }
  },
  ...(!isProd
    ? [
        {
          id: 'canals-sub',
          key: 'canals',
          text: 'Canals',
          path: '/mpp/commercial-analytics/canals',
          onClick: () => {
            routeChange('/mpp/commercial-analytics/canals');
          }
        }
      ]
    : [])
];

const getVoyages = (routeChange, uriExt) => [
  {
    id: 'completed-voyage-sub',
    key: 'voyage-analytics-sub',
    text: 'Completed Voyage',
    path: `/${uriExt}/completed-voyages`,
    onClick: () => {
      routeChange(`/${uriExt}/completed-voyages`);
    }
  },
  {
    id: 'ongoing-voyage-sub',
    key: 'ongoing-voyage-sub',
    text: (
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="body2">Ongoing Voyage</Typography>
        <Typography
          component="span"
          sx={{
            color: 'red',
            fontStyle: 'italic',
            fontSize: '0.8em'
          }}>
          New
        </Typography>
      </Box>
    ),
    path: `/${uriExt}/ongoing-voyages`,
    onClick: () => {
      routeChange(`/${uriExt}/ongoing-voyages`);
    }
  }
];
export const getSideMenuList = (segment, routeChange, menuOpen, dropDownMenuOpen, dispatch) => {
  const handleSideMenuDropDownOpen = (val) => {
    dispatch(setSideMenuItemOpen({ ...dropDownMenuOpen, ...val }));
  };

  useEffect(() => {
    if (!menuOpen) {
      dispatch(closeAllSideMenuItems);
    }
  }, [menuOpen]);
  if (!segment) {
    return [];
  }
  const menuItems = [
    {
      key: 'dashboard',
      id: 'dashboard-menu',
      icon: <DashboardIcon color={'inherit'} />,
      text: 'Dashboard',
      onClick: () => {
        routeChange('/roro/dashboard');
      }
    },
    {
      key: 'commercial-analytics',
      id: 'commercial-analytics-menu',
      icon: <ChartColumnIcon color="inherit" />,
      text: 'Market Insights',
      onClick: () => {
        handleSideMenuDropDownOpen({ saOpen: !dropDownMenuOpen?.saOpen });
      },
      subSectionOpen: dropDownMenuOpen.saOpen,
      subSections: segment.id === SEGMENT.RoRo.id ? getSaRoRo(routeChange) : getSaMpp(routeChange)
    },
    {
      key: 'voyage-analytics',
      id: 'voyage-analytics-menu',
      icon: <InsightsOutlinedIcon color="inherit" />,
      text: 'Voyage Analytics',
      onClick: () => {
        handleSideMenuDropDownOpen({ vaOpen: !dropDownMenuOpen?.vaOpen });
      },
      subSectionOpen: dropDownMenuOpen.vaOpen,
      subSections: getVoyages(routeChange, segment.id === SEGMENT.RoRo.id ? 'roro' : 'mpp')
    },
    {
      key: 'emissions-analytics',
      id: 'emissions-analytics-menu',
      icon: <SpeedometerIcon color="inherit" />,
      text: 'Emissions Analytics',
      onClick: () => {
        handleSideMenuDropDownOpen({ emissionsOpen: !dropDownMenuOpen?.emissionsOpen });
      },
      subSectionOpen: dropDownMenuOpen.emissionsOpen,
      subSections: getEmissions(routeChange, segment.id === SEGMENT.RoRo.id ? 'roro' : 'mpp')
    }
  ];

  return menuItems.filter((item) => item.id !== 'dashboard-menu' || segment.id === SEGMENT.RoRo.id);
};
