export const drawRegionPolygon = (map, regionPolygon) => {
  if (map.getLayer('maine')) {
    map.removeLayer('maine');
  }
  if (map.getLayer('outline')) {
    map.removeLayer('outline');
  }
  if (map.getSource('source')) {
    map.removeSource('source');
  }
  const polygonData = convertPolygonToCoordinates(regionPolygon);

  // Add a data source containing GeoJSON data.
  map.addSource('source', {
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        // These coordinates outline Maine.
        coordinates: [polygonData]
      }
    }
  });

  // Add a new layer to visualize the polygon.
  map.addLayer({
    id: 'maine',
    type: 'fill',
    source: 'source', // reference the data source
    layout: {},
    paint: {
      'fill-color': 'rgba(217,217,217,0.5)', // blue color fill
      'fill-opacity': 0.5
    }
  });

  // Add a black outline around the polygon.
  map.addLayer({
    id: 'outline',
    type: 'line',
    source: 'source',
    layout: {},
    paint: {
      'line-color': '#B2EBF3',
      'line-width': 1
    }
  });
};

// Map Fullscreen controls

const setFullScreenButtonContent = (button, buttonText, buttonImageSrc) => {
  button.innerHTML = '';
  // Create image element
  const image = document.createElement('img');
  image.src = buttonImageSrc;
  image.style.width = '20px'; // Adjust size as needed
  image.style.height = '20px'; // Adjust size as needed
  image.style.verticalAlign = 'middle'; // Align image with text
  image.alt = 'Fullscreen';
  // Create text span
  const text = document.createElement('span');
  text.style.marginLeft = '5px'; // Adjust spacing as needed
  text.textContent = buttonText;
  // Append elements to the button
  button.appendChild(image);
  button.appendChild(text);
  // Additional styling for the button
  button.style.display = 'flex';
  button.style.alignItems = 'center';
  button.style.justifyContent = 'center';
};
export const fullScreenbtnFunc = (fullscreenButton) => {
  setFullScreenButtonContent(
    fullscreenButton,
    ' Fullscreen',
    '/assets/images/full-screen-icon.png'
  );
};

export const exitFullScreenbtnFunc = (shrinkscreenButton) => {
  setFullScreenButtonContent(
    shrinkscreenButton,
    ' Exit Fullscreen',
    '/assets/images/shrink-screen-icon.png'
  );
};

export const convertPolygonToCoordinates = (polygon) => {
  const polygonString = polygon.replace('POLYGON ((', '').replace('))', '');
  const coordinatePairs = polygonString.split(', ');

  return coordinatePairs.map((pair) => {
    const [longitude, latitude] = pair.split(' ');
    return [parseFloat(longitude), parseFloat(latitude)];
  });
};
