import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl, { Map as Mapbox, NavigationControl } from 'mapbox-gl';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { Button, Paper, Typography } from '@esgian/esgianui';
import { useSearchParams } from 'react-router-dom';
import {
  applyLayerPaintProperties,
  attachMarkers,
  createMapPopup,
  createPulsingMarker,
  getLayerConfig,
  getMarkers,
  getRouteSource,
  putMarkerOnMap,
  removeMarkerFromMap,
  removeMarkers
} from '../VoyageSummarySection/VoyageSummaryRouteMap/utils';
import { generateVoyageIdFromSavedVoyage, VoyageType } from '../VoyageUtils';

const getStartAndEndMarkerId = (routeId) => [`start-${routeId}`, `end-${routeId}`];

const normalLineWidth = 1;
const selectedLineWidth = 4;
const normalLineOpacity = 0.7;
const selectedLineOpacity = 1;
const normalLineDashArray = [1, 2];
const selectedLineDashArray = [1, 0];

export const routeColors = [
  '#80DFEB',
  '#DD53FF',
  '#FFD465',
  '#0E9EFF',
  '#FFDAF9',
  '#4BAC3C',
  '#A47AFF',
  '#E3FFA7',
  '#F8A457',
  '#1557FF'
];

mapboxgl.accessToken = MAPBOX_API_TOKEN;

function VoyageGroupRouteMap({
  voyageGroupWithVoyageDetails,
  selectedVesselsIdList,
  hoveredVoyageId,
  routeOperatorColorMap
}) {
  const themeMode = useSelector(getThemeMode);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const portsMarkerRef = useRef(null);
  const markersRef = useRef({}); // Stores the references to start and end markers
  const vesselNamePopupRef = useRef(new Map());

  const [searchParams, setSearchParams] = useSearchParams();

  const isFullScreen = searchParams.get('view') === 'map';
  const showColorCode = searchParams.get('showColorCode') === 'true';
  const isShowPorts = searchParams.get('showPorts') === 'true';
  const isShowVesselNameChecked = searchParams.get('showVesselName') === 'true';

  const [isStyleLoaded, setIsStyleLoaded] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const isOngoingVoyageGroup = voyageGroupWithVoyageDetails?.type === VoyageType.ONGOING;

  const routes = useMemo(
    () =>
      voyageGroupWithVoyageDetails?.voyages.map((v) => ({
        routes: v.details.voyageReducedTransitDetails.transitDetails ?? [],
        id: generateVoyageIdFromSavedVoyage(v),
        ports: v.details.voyagePortCalls.portCalls ?? [],
        operator: v.details.operator,
        vesselName: v.details.voyageOverview.vesselName
      })),
    [voyageGroupWithVoyageDetails]
  );

  const goFullScreen = () => {
    setSearchParams((prevParams) => {
      prevParams.set('view', 'map');
      prevParams.set('showPorts', 'true');
      prevParams.set('showColorCode', 'true');
      prevParams.set('showVesselName', 'false');
      return prevParams;
    });
  };

  const putStartAndEndMarker = (startMarkerId, endMarkerId, route, isVisibleStartMarker) => {
    if (!markersRef.current || !mapRef.current || route.routes.length === 0) {
      return;
    }
    // Remove existing markers if they exist
    if (markersRef.current[startMarkerId]) {
      removeMarkerFromMap(mapRef.current, startMarkerId);
    }
    if (markersRef.current[endMarkerId]) {
      removeMarkerFromMap(mapRef.current, endMarkerId);
    }

    const pulsingMarkerStart = createPulsingMarker(
      mapRef.current,
      'rgba(14,81,255,1)',
      !isOngoingVoyageGroup
    );
    const pulsingMarkerEnd = createPulsingMarker(
      mapRef.current,
      isOngoingVoyageGroup ? '#EF5350' : 'rgba(255,152,0)'
    );

    markersRef.current[startMarkerId] = pulsingMarkerStart;
    markersRef.current[endMarkerId] = pulsingMarkerEnd;

    putMarkerOnMap(
      mapRef.current,
      pulsingMarkerStart,
      route.routes[0].lon,
      route.routes[0].lat,
      startMarkerId,
      !!isVisibleStartMarker
    );
    putMarkerOnMap(
      mapRef.current,
      pulsingMarkerEnd,
      route.routes[route.routes.length - 1].lon,
      route.routes[route.routes.length - 1].lat,
      endMarkerId
    );
  };

  useEffect(() => {
    if (mapRef.current || (routes?.length ?? 0) === 0) {
      setIsStyleLoaded(false);
      return;
    }
    const map = new Mapbox({
      container: mapContainerRef.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [113.55, 22.1667],
      zoom: 1
    });
    mapRef.current = map;
    map.addControl(new NavigationControl({}));

    portsMarkerRef.current = getMarkers(
      routes.reduce(
        (prevPortsMarker, currentRoute) => [
          ...prevPortsMarker,
          ...currentRoute.ports.map((port) => ({ ...port, vesselName: currentRoute.vesselName }))
        ],
        []
      ),
      map,
      {
        portsColor: '#fff'
      }
    );
    map.on('load', () => {
      routes.forEach((r) => {
        const [startMarkerId, endMarkerId] = getStartAndEndMarkerId(r.id);

        mapRef.current.addSource(
          r.id,
          getRouteSource([
            {
              route: r.routes,
              color: showColorCode
                ? routeOperatorColorMap[r.operator]
                : themeMode
                ? routeColors[0]
                : routeColors[9]
            }
          ])
        );
        mapRef.current.addLayer(
          getLayerConfig(r.id, {
            opacity: normalLineOpacity,
            lineDashArray: normalLineDashArray,
            lineWidth: normalLineWidth
          })
        );

        putStartAndEndMarker(startMarkerId, endMarkerId, r);

        if ((r.routes?.length ?? 0) === 0) {
          return;
        }

        const startPortPopup = createMapPopup(r.vesselName, r.routes[0].lon, r.routes[0].lat);
        const endPortPopup = createMapPopup(
          r.vesselName,
          r.routes[r.routes.length - 1].lon,
          r.routes[r.routes.length - 1].lat
        );
        vesselNamePopupRef.current.set(r.id, { startPortPopup, endPortPopup });
        setIsMapLoaded(true);
      });
      if (isShowPorts) {
        attachMarkers(portsMarkerRef.current, map);
      }
    });

    map.on('styledata', () => {
      setIsStyleLoaded(true);
    });

    return () => map.remove();
  }, [routes]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    mapRef.current.setStyle(themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE);

    mapRef.current.once('styledata', () => {
      routes.forEach((r) => {
        if (!mapRef.current.getSource(r.id)) {
          mapRef.current.addSource(
            r.id,
            getRouteSource([
              {
                route: r.routes,
                color: showColorCode
                  ? routeOperatorColorMap?.[r.operator] ?? routeColors[0]
                  : themeMode
                  ? routeColors[0]
                  : routeColors[9]
              }
            ])
          );
        }
        if (!mapRef.current.getLayer(r.id)) {
          mapRef.current.addLayer(getLayerConfig(r.id));
        }
      });

      if (isShowPorts) {
        attachMarkers(portsMarkerRef.current, mapRef.current);
      } else {
        removeMarkers(portsMarkerRef.current);
      }

      // Re-add pulsing markers after style change
      routes.forEach((r) => {
        const [startMarkerId, endMarkerId] = getStartAndEndMarkerId(r.id);

        putStartAndEndMarker(startMarkerId, endMarkerId, r);
      });
    });
  }, [themeMode, isOngoingVoyageGroup]);

  useEffect(() => {
    if (!mapRef.current || !portsMarkerRef.current) {
      return;
    }
    removeMarkers(portsMarkerRef.current);
    updateVisiblePortMarkers();
    if (isShowPorts) {
      attachMarkers(portsMarkerRef.current, mapRef.current);
    } else {
      removeMarkers(portsMarkerRef.current);
    }
  }, [isShowPorts]);

  const updateVisiblePortMarkers = () => {
    portsMarkerRef.current = getMarkers(
      routes
        .filter((r) => selectedVesselsIdList.includes(r.id))
        .reduce(
          (prevPortsMarker, currentRoute) => [
            ...prevPortsMarker,
            ...currentRoute.ports.map((port) => ({ ...port, vesselName: currentRoute.vesselName }))
          ],
          []
        ),
      mapRef.current,
      {
        portsColor: '#a0d9ef'
      }
    );
  };

  useEffect(() => {
    if (!mapRef.current || !isMapLoaded || !routes?.length) {
      return;
    }

    if (isShowPorts) {
      removeMarkers(portsMarkerRef.current);
      updateVisiblePortMarkers();
      attachMarkers(portsMarkerRef.current, mapRef.current);
    }

    routes.forEach((r) => {
      const isLayerExist = mapRef?.current?.getLayer(r.id);
      if (!isLayerExist) {
        return;
      }
      const [startMarkerId, endMarkerId] = getStartAndEndMarkerId(r.id);

      if (!isFullScreen && hoveredVoyageId && hoveredVoyageId === r.id) {
        applyLayerPaintProperties(mapRef.current, r.id, {
          lineWidth: selectedLineWidth,
          lineColor: routeColors[1],
          lineOpacity: selectedLineOpacity,
          lineDasharray: selectedLineDashArray
        });
      } else if (!isFullScreen) {
        applyLayerPaintProperties(mapRef.current, r.id, {
          lineWidth: normalLineWidth,
          lineColor: themeMode ? routeColors[0] : routeColors[9],
          lineOpacity: normalLineOpacity,
          lineDasharray: normalLineDashArray
        });
      }

      const isVisible = selectedVesselsIdList.includes(r.id);
      mapRef?.current?.setLayoutProperty(r.id, 'visibility', isVisible ? 'visible' : 'none');
      // Remove start and end markers if the route is not visible
      if (
        (!isVisible && isOngoingVoyageGroup && isFullScreen) ||
        (hoveredVoyageId && hoveredVoyageId !== r.id)
      ) {
        removeMarkerFromMap(mapRef.current, startMarkerId);
        removeMarkerFromMap(mapRef.current, endMarkerId);
        delete markersRef.current[startMarkerId];
        delete markersRef.current[endMarkerId];
      } else {
        putStartAndEndMarker(startMarkerId, endMarkerId, r, hoveredVoyageId === r.id);
      }
    });
  }, [selectedVesselsIdList, isStyleLoaded, hoveredVoyageId, isOngoingVoyageGroup]);

  useEffect(() => {
    if (!isMapLoaded || vesselNamePopupRef.current.size === 0) {
      return;
    }

    vesselNamePopupRef.current.forEach(({ endPortPopup }, routeId) => {
      const shouldShowPopup = selectedVesselsIdList.includes(routeId) && isShowVesselNameChecked;
      if (!shouldShowPopup) {
        endPortPopup.remove();
        return;
      }
      endPortPopup.addTo(mapRef.current);
    });
  }, [selectedVesselsIdList, isMapLoaded, isShowVesselNameChecked]);

  useEffect(() => {
    if (!mapRef.current || !isStyleLoaded) {
      return;
    }
    routes.forEach((r) => {
      const geojsonSource = mapRef.current.getSource(r.id);
      if (!geojsonSource) return;
      // Update the data after the GeoJSON source was created
      geojsonSource.setData(
        getRouteSource([
          {
            route: r.routes,
            color: showColorCode ? routeOperatorColorMap[r.operator] : routeColors[0]
          }
        ]).data
      );
    });
  }, [showColorCode, isMapLoaded]);

  return (
    <Paper sx={{ position: 'relative' }}>
      {!isFullScreen && (
        <Typography variant={'h6'} sx={{ position: 'absolute', zIndex: 20, p: 2 }}>
          Voyage routes on Map
        </Typography>
      )}

      <div
        style={{
          position: 'absolute',
          zIndex: 20,
          right: '10px',
          bottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          gap: 2
        }}>
        {!isFullScreen && (
          <Button
            onClick={() => goFullScreen()}
            startIcon={<img src="/assets/images/full-screen-icon.png" alt="Full screen" />}
            sx={{ background: 'white', color: 'black' }}>
            <span>Full screen</span>
          </Button>
        )}
      </div>

      <div
        className="voyage-summary-map-container"
        ref={mapContainerRef}
        style={{
          height: isFullScreen ? '100vh' : '90vh',
          width: isFullScreen ? '100vw' : '100%',
          borderRadius: 4
        }}
      />
    </Paper>
  );
}

VoyageGroupRouteMap.propTypes = {
  voyageGroupWithVoyageDetails: PropTypes.object,
  selectedVesselsIdList: PropTypes.array,
  hoveredVoyageId: PropTypes.string,
  routeOperatorColorMap: PropTypes.object
};

export default VoyageGroupRouteMap;
