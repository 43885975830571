import {
  Box,
  EditIcon,
  IconButton,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Typography
} from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import { getVesselProfile } from '@api';
import { useSegment } from '@hooks';
import VesselOverview from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselOverviewSection/VesselOverview/VesselOverview';
import { formatNumberReadable } from '@helpers';
import SelectDepartureAndArrivalPort, { PortSelectionType } from '../SelectDepartureAndArrivalPort';

const AdjacentPortsDirection = {
  NEXT: 'next',
  PREVIOUS: 'previous'
};
const getAdjacentPorts = (
  portCalls,
  currentPort,
  count,
  direction = AdjacentPortsDirection.NEXT
) => {
  const currentIndex = portCalls.findIndex(
    (port) => port.portId === currentPort.portId && port.arrivalDate === currentPort.arrivalDate
  );
  const isNext = direction === AdjacentPortsDirection.NEXT;
  const start = isNext ? currentIndex + 1 : Math.max(0, currentIndex - count);
  const end = isNext ? currentIndex + 1 + count : currentIndex;

  return portCalls.slice(start, end);
};

const VoyageSummaryOverview = ({
  isLoading,
  summaryOverview,
  voyageTransitDetails,
  latestPortCall,
  isOngoingVoyage,
  voyagePortCalls = [],
  onUpdateDepartureAndArrivalPort
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoadingVesselsData, setIsLoadingVesselsData] = useState(false);
  const [vesselsData, setVesselsData] = useState(null);
  const { isRoRo } = useSegment();
  const [currentImo, setCurrentImo] = useState(null);
  const [updatedPortSelectionType, setUpdatedPortSelectionType] = useState();

  const currentDeparturePort = voyagePortCalls.find(
    (port) =>
      port.portId === voyageTransitDetails?.fromPortId &&
      port.departureDate === voyageTransitDetails?.startDate
  );
  const currentArrivalPort = voyagePortCalls.find(
    (port) =>
      port.portId === voyageTransitDetails?.toPortId &&
      port.arrivalDate === voyageTransitDetails?.endDate
  );

  const nexFiveDeparturePortCalls =
    isOngoingVoyage || !voyagePortCalls.length
      ? []
      : getAdjacentPorts(voyagePortCalls, currentDeparturePort, 5);
  const previousFiveArrivalPortCalls =
    isOngoingVoyage || !voyagePortCalls.length
      ? []
      : getAdjacentPorts(voyagePortCalls, currentArrivalPort, 5, AdjacentPortsDirection.PREVIOUS);

  const renderEditPortAction = (portSelectionType, isDisabled) => {
    if (!voyagePortCalls?.length || isOngoingVoyage) return null;
    return (
      <IconButton
        disabled={isDisabled}
        onClick={() => {
          setUpdatedPortSelectionType(portSelectionType);
        }}
        sx={{
          m: 0
        }}>
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  const handlePopoverOpen = (event, imo) => {
    setAnchorEl(event.currentTarget);
    setCurrentImo(imo);
    if (!vesselsData || imo !== currentImo) {
      setIsLoadingVesselsData(true);
      const controller = new AbortController();
      const { signal } = controller;
      getVesselProfile({ imo: imo }, signal)
        .then((result) => {
          setVesselsData(result);
        })
        .finally(() => {
          setIsLoadingVesselsData(false);
        });
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCurrentImo(null);
  };

  const arrivalPortData = {
    label: 'Arrival Port:',
    value: isOngoingVoyage ? '-' : summaryOverview?.arrivalPort,
    id: voyageTransitDetails?.toPortId,
    renderAction: () =>
      renderEditPortAction(PortSelectionType.ARRIVAL, previousFiveArrivalPortCalls.length < 2)
  };

  const arrivalTimeData = {
    label: 'Arrival Time:',
    value: moment(summaryOverview?.arrivalTime).format('YYYY-MM-DD HH:mm:ss')
  };

  const intermediatePortData = {
    label: isOngoingVoyage ? 'No. of Port visited:' : 'No. of Intermediate Port Calls:',
    value: summaryOverview?.numPortsVisited
  };

  const transitTimeData = {
    label: 'Transit Time:',
    value: voyageTransitDetails?.transitDays?.toFixed(2) + ' Days'
  };

  const totalDistanceData = {
    label: 'Total Distance:',
    value: formatNumberReadable(voyageTransitDetails?.totalDistance?.toFixed(0)) + ' NM'
  };

  const overviewData = [
    { label: 'Vessel Name:', value: summaryOverview?.vesselName, id: summaryOverview?.imo },
    {
      label: isOngoingVoyage ? 'Latest Port call' : 'Departure Port:',
      value: isOngoingVoyage ? latestPortCall?.portName : summaryOverview?.departurePort,
      id: voyageTransitDetails?.fromPortId,
      renderAction: () =>
        renderEditPortAction(PortSelectionType.DEPARTURE, nexFiveDeparturePortCalls.length < 2)
    },
    {
      label: 'Departure Time:',
      value: isOngoingVoyage
        ? moment(latestPortCall?.endDate).format('YYYY-MM-DD HH:mm:ss')
        : moment(summaryOverview?.departureTime).format('YYYY-MM-DD HH:mm:ss')
    },
    arrivalPortData,
    arrivalTimeData,
    intermediatePortData,
    transitTimeData,
    totalDistanceData
  ];

  return (
    <>
      <Paper
        sx={{
          p: isOngoingVoyage ? 0 : 2,
          backgroundColor: isOngoingVoyage ? 'transparent' : undefined,
          height: '100%'
        }}>
        <Stack>
          <Typography variant={'h6'} align="left" sx={{ p: 2 }}>
            Overview
          </Typography>
          {isLoading ? (
            <Skeleton variant={'rectangular'} height={'25vh'}></Skeleton>
          ) : (
            <ul>
              {overviewData
                .filter((d) =>
                  isOngoingVoyage
                    ? d.label !== arrivalTimeData.label &&
                      d.label !== arrivalPortData.label &&
                      d.label !== transitTimeData.label &&
                      d.label !== totalDistanceData.label
                    : true
                )
                .map(({ label, value, id, renderAction }) => {
                  const isVesselName = label === 'Vessel Name:';
                  return (
                    <li key={label} style={{ marginBottom: '8px' }}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}>
                        <Typography variant={'body1'}>{label}</Typography>
                        <Box display={'flex'} alignItems={'center'}>
                          {(isVesselName || !id) && (
                            <Typography
                              mr={2}
                              variant={'body1'}
                              onMouseEnter={
                                isVesselName ? (e) => handlePopoverOpen(e, id) : undefined
                              }
                              onMouseLeave={isVesselName ? (e) => handlePopoverClose(e) : undefined}
                              sx={{ cursor: isVesselName ? 'pointer' : 'default' }}>
                              {value}
                            </Typography>
                          )}

                          {id &&
                            !isVesselName &&
                            !(isOngoingVoyage && label === arrivalPortData.label) && (
                              <Box>
                                <ProfileLink
                                  uriParams={'section=1'}
                                  profile={label === 'Vessel Name:' ? 'vessel' : 'port'}
                                  id={id}
                                  name={
                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                      <Typography>{value}</Typography>
                                    </Stack>
                                  }
                                />
                              </Box>
                            )}
                          {renderAction && renderAction()}
                        </Box>
                        {isVesselName && (
                          <Popover
                            id="voyage-info-popover"
                            sx={{
                              pointerEvents: 'none'
                            }}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            PaperProps={{
                              onMouseEnter: (e) => handlePopoverOpen(e, id),
                              onMouseLeave: handlePopoverClose,
                              style: { pointerEvents: 'auto' }
                            }}>
                            <VesselOverview
                              vesselsData={vesselsData}
                              loading={isLoadingVesselsData}
                              isRoRo={isRoRo}
                              title="Voyage Specification"
                              width="700px"
                              showTitle={false}
                            />
                          </Popover>
                        )}
                      </Stack>
                    </li>
                  );
                })}
            </ul>
          )}
        </Stack>
      </Paper>
      {!isOngoingVoyage &&
        voyageTransitDetails?.fromPortId &&
        voyageTransitDetails?.toPortId &&
        updatedPortSelectionType && (
          <SelectDepartureAndArrivalPort
            open
            onClose={() => {
              setUpdatedPortSelectionType(undefined);
            }}
            onSave={(selectedDepartureAndArrivalPort) => {
              onUpdateDepartureAndArrivalPort?.(selectedDepartureAndArrivalPort);
              setUpdatedPortSelectionType(undefined);
            }}
            arrivalPorts={previousFiveArrivalPortCalls}
            departurePorts={nexFiveDeparturePortCalls}
            currentDeparturePort={currentDeparturePort}
            currentArrivalPort={currentArrivalPort}
            portSelectionType={updatedPortSelectionType}
          />
        )}
    </>
  );
};

export default VoyageSummaryOverview;

VoyageSummaryOverview.propTypes = {
  summaryOverview: PropTypes.object,
  latestPortCall: PropTypes.object,
  isLoading: PropTypes.bool,
  voyageTransitDetails: PropTypes.object,
  isOngoingVoyage: PropTypes.bool,
  voyagePortCalls: PropTypes.array,
  onUpdateDepartureAndArrivalPort: PropTypes.func
};

VoyageSummaryOverview.defaultProps = {
  summaryOverview: null,
  isLoading: false,
  voyageTransitDetails: null
};
