import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useTheme, useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

const getYaxis = (maxTotalCEU, avgColor, maxAvgCEU, isRoRo) => [
  {
    title: {
      text: isRoRo ? 'Total CEU' : 'Total Deadweight'
    },
    max: maxTotalCEU * 1.1,
    forceNiceScale: true,
    tickAmount: 5,
    decimalsInFloat: 0
  },
  {
    opposite: true,
    seriesName: isRoRo ? 'Average CEU' : 'Average Deadweight',
    max: maxAvgCEU * 1.25,
    min: 0,
    forceNiceScale: true,
    tickAmount: 5,
    decimalsInFloat: 0,
    labels: {
      style: {
        colors: avgColor
      }
    },
    title: {
      text: isRoRo ? 'Average CEU' : 'Average Deadweight',
      style: {
        color: avgColor
      }
    }
  }
];

const getOptions = (
  categories,
  theme,
  user,
  maxTotalCEU,
  fileName,
  maxAvgCEU,
  isRoRo,
  vesselsAdded,
  vesselsRemoved,
  showForSingleSeries
) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, twoColorsContrast }
    }
  } = theme;

  return {
    chart: {
      animations: {
        enabled: false
      },
      type: 'bar',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: false
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val?.toFixed(2);
      }
    },
    colors: [twoColorsContrast[1], twoColorsContrast[0]],
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories,
      title: {},
      labels: { rotateAlways: true }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      custom: ({ dataPointIndex, w }) => {
        const vesselInNames =
          vesselsAdded[dataPointIndex]?.map((vessel) => vessel.vesselName).join('<br />') || '--';
        const vesselOutNames =
          vesselsRemoved[dataPointIndex]?.map((vessel) => vessel.vesselName).join('<br/>') || '--';

        const defaultTooltip = w.globals.series
          .map((s, idx) => {
            return `
            <div style="display: flex; align-items: center;">
              <span style="background-color: ${w.globals.colors[idx]}; width: 10px; height: 10px; border-radius: 50%; display: inline-block; margin-right: 5px;"></span>
              <span style="color: ${w.globals.colors[idx]}">${w.globals.seriesNames[idx]}: ${s[dataPointIndex]}</span>
            </div>
          `;
          })
          .join('');

        return `
          <div style="padding: 4px;">
            <strong>${categories[dataPointIndex]}</strong><br/>
            ${defaultTooltip}
            ${
              showForSingleSeries
                ? `
            <hr/>
            <strong>Vessels Added:</strong><br />
            ${vesselInNames}<br />
            <strong>Vessels Removed:</strong><br />
            ${vesselOutNames}
          `
                : ''
            }
          </div>
        `;
      }
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetX: -35,
      horizontalAlign: 'left'
    },
    yaxis: getYaxis(maxTotalCEU, twoColorsContrast[0], maxAvgCEU, isRoRo),
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: 'center',
          total: {
            label: { color: 'primary', background: 'transparent' },
            style: {
              fontsize: '10px'
            }
          }
        }
      }
    },
    stroke: {
      width: [2, 2, 3],
      curve: 'straight',
      dashArray: [0, 5]
    },
    markers: {
      size: [0, 0, 2],
      strokewidth: 2,
      hover: {
        size: 5
      }
    }
  };
};

function OperatorCEUChart({
  loading,
  CEUCapacityData,
  fileName,
  categories,
  vesselsAdded,
  vesselsRemoved,
  showForSingleSeries
}) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [maxTotalCEU, setMaxTotalCEU] = useState(0);
  const [maxAvgCEU, setMaxAvgCEU] = useState(0);
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const { isRoRo } = useSegment();

  useEffect(() => {
    if (!CEUCapacityData?.length) return;

    let maxTotal = 0;
    let maxAvg = 0;
    let maxTotalDWT = 0;
    let maxAvgDWT = 0;

    const totalCEUData = CEUCapacityData.slice(1).map(({ totalCEU }) => {
      maxTotal = Math.max(maxTotal, totalCEU || 0);
      return totalCEU;
    });

    const averageCEUData = CEUCapacityData.slice(1).map(({ averageCEU }) => {
      maxAvg = Math.max(maxAvg, averageCEU || 0);
      return averageCEU?.toFixed(0);
    });

    const totalDWTData = CEUCapacityData.slice(1).map(({ totalDeadweightTonnage }) => {
      maxTotalDWT = Math.max(maxTotal, totalDeadweightTonnage || 0);
      return totalDeadweightTonnage;
    });

    const averageDWTData = CEUCapacityData.slice(1).map(({ averageDeadweightTonnage }) => {
      maxAvgDWT = Math.max(maxAvg, averageDeadweightTonnage || 0);
      return averageDeadweightTonnage?.toFixed(0);
    });

    setSeries([
      {
        name: isRoRo ? 'Total CEU' : 'Total Deadweight',
        type: 'column',
        data: isRoRo ? totalCEUData : totalDWTData
      },
      {
        name: isRoRo ? 'Average CEU' : 'Average Deadweight',
        type: 'line',
        data: isRoRo ? averageCEUData : averageDWTData
      }
    ]);

    setMaxTotalCEU(isRoRo ? maxTotal : maxTotalDWT);
    setMaxAvgCEU(isRoRo ? maxAvg : maxAvgDWT);
  }, [CEUCapacityData]);

  useEffect(() => {
    if (!CEUCapacityData?.length) return;
    setOptions(
      getOptions(
        categories.slice(1),
        theme,
        user,
        maxTotalCEU,
        fileName,
        maxAvgCEU,
        isRoRo,
        vesselsAdded.slice(1),
        vesselsRemoved.slice(1),
        showForSingleSeries
      )
    );
  }, [theme, series, categories, maxTotalCEU, maxAvgCEU]);
  return (
    <ApexChart loading={loading} height={480} options={options || {}} type={'line'} data={series} />
  );
}

OperatorCEUChart.propTypes = {
  loading: PropTypes.bool,
  CEUCapacityData: PropTypes.arrayOf(PropTypes.object),
  fileName: PropTypes.string,
  categories: PropTypes.array,
  vesselsAdded: PropTypes.array,
  vesselsRemoved: PropTypes.array,
  showForSingleSeries: PropTypes.bool
};

OperatorCEUChart.defaultProps = {
  CEUCapacityData: [],
  loading: false,
  fileName: '',
  categories: [],
  vesselsRemoved: [],
  vesselsAdded: [],
  showForSingleSeries: false
};

export default OperatorCEUChart;
