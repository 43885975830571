import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import {
  BasicTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@esgian/esgianui';
import moment from 'moment';

const getDefaultOperatorSetWeek = (startDate, endDate) => {
  let startDateClone = startDate.clone();
  let endDateClone = endDate.clone();
  let res = {};
  while (
    startDateClone &&
    endDateClone &&
    startDateClone.isSameOrBefore(endDateClone.endOf('isoWeek'))
  ) {
    let weekNumber = startDateClone.isoWeek();
    let yearNumber = startDateClone.isoWeekYear();
    let key = `${yearNumber}_${weekNumber}`;
    res[key] = {
      year: yearNumber,
      week: weekNumber,
      value: 0
    };

    startDateClone.add(1, 'isoWeek');
  }
  return res;
};

const getDefaultOperatorSetMonth = (startDate, endDate) => {
  let startDateClone = startDate.clone();
  let endDateClone = endDate.clone();
  let res = {};
  while (startDateClone && endDateClone && startDateClone.isSameOrBefore(endDateClone)) {
    let monthNumber = startDateClone.month();
    let yearNumber = startDateClone.year();
    let key = `${yearNumber}_${monthNumber}`;
    res[key] = {
      year: yearNumber,
      month: monthNumber,
      value: 0
    };

    startDateClone.add(1, 'month');
  }
  return res;
};

function CanalTransitsTableOperator({
  startDate = null,
  endDate = null,
  loading = false,
  canalTransits = null,
  timeFrame = 'weekly',
  fileName = ''
}) {
  const user = useSelector(getUser);

  const weeklyTableData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let dataSet = {};

    canalTransits?.forEach(({ canalArrival, operatorId, operatorShortName }) => {
      if (!dataSet[operatorId]) {
        dataSet[operatorId] = {
          operatorName: operatorShortName,
          data: getDefaultOperatorSetWeek(startDate, endDate)
        };
      }
      let arrival = moment(canalArrival);
      let weekNumber = arrival.clone().isoWeek();
      let yearNumber = arrival.clone().isoWeekYear();
      let key = `${yearNumber}_${weekNumber}`;

      dataSet[operatorId].data[key].value += 1;
    });

    return dataSet;
  }, [canalTransits]);

  const monthlyTableData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let dataSet = {};

    canalTransits?.forEach(({ canalArrival, operatorId, operatorShortName }) => {
      if (!dataSet[operatorId]) {
        dataSet[operatorId] = {
          operatorName: operatorShortName,
          data: getDefaultOperatorSetMonth(startDate, endDate)
        };
      }
      let arrival = moment(canalArrival);
      let weekNumber = arrival.clone().month();
      let yearNumber = arrival.clone().year();
      let key = `${yearNumber}_${weekNumber}`;

      dataSet[operatorId].data[key].value += 1;
    });

    return dataSet;
  }, [canalTransits]);

  let yearGroups = [];
  let timeColumns = {};
  let opNames = {};

  if (timeFrame === 'weekly') {
    Object.keys(weeklyTableData).forEach((operatorId) => {
      const opData = weeklyTableData[operatorId].data;

      opNames[operatorId] = {
        operatorName: weeklyTableData[operatorId].operatorName,
        data: {}
      };
      Object.keys(opData).forEach((weeklyData) => {
        const weekData = opData[weeklyData];
        if (!yearGroups.find(({ mentionedYear }) => mentionedYear === weekData.year)) {
          yearGroups.push({
            mentionedYear: weekData.year,
            yearCount: 0
          });
          Object.keys(opData).forEach((opRow) => {
            if (
              yearGroups.find(
                ({ mentionedYear }) =>
                  mentionedYear === opData[opRow].year && mentionedYear === weekData.year
              )
            ) {
              yearGroups.find(
                ({ mentionedYear }) =>
                  mentionedYear === opData[opRow].year && mentionedYear === weekData.year
              ).yearCount++;
            }
          });
        }
        if (!timeColumns[`${weekData.year}_${weekData.week}`]) {
          timeColumns[`${weekData.year}_${weekData.week}`] = 'Week ' + weekData.week;
        }

        opNames[operatorId].data[`${weekData.year}_${weekData.week}`] = weekData.value;
      });
    });
  } else {
    Object.keys(monthlyTableData).forEach((operatorId) => {
      const opData = monthlyTableData[operatorId].data;

      opNames[operatorId] = {
        operatorName: monthlyTableData[operatorId].operatorName,
        data: {}
      };
      Object.keys(opData).forEach((monthlyData) => {
        const monthData = opData[monthlyData];
        if (!yearGroups.find(({ mentionedYear }) => mentionedYear === monthData.year)) {
          yearGroups.push({
            mentionedYear: monthData.year,
            yearCount: 0
          });
          Object.keys(opData).forEach((opRow) => {
            if (
              yearGroups.find(
                ({ mentionedYear }) =>
                  mentionedYear === opData[opRow].year && mentionedYear === monthData.year
              )
            ) {
              yearGroups.find(
                ({ mentionedYear }) =>
                  mentionedYear === opData[opRow].year && mentionedYear === monthData.year
              ).yearCount++;
            }
          });
        }
        if (!timeColumns[`${monthData.year}_${monthData.month}`]) {
          timeColumns[`${monthData.year}_${monthData.month}`] =
            moment().month(monthData.month).format('MMM') + ' ' + monthData.year;
        }

        opNames[operatorId].data[`${monthData.year}_${monthData.month}`] = monthData.value;
      });
    });
  }

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      fixedHeader: true,
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      downloadOptions: {
        filename: fileName
      },
      setTableProps: () => ({
        id: timeFrame === 'weekly' ? 'weekly-canal-transits-table' : 'monthly-canal-transits-table'
      }),
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '20vh',
      tableBodyHeight: '20vh',
      rowsPerPageOptions: [10, 25, 50, 100]
    };
  }, [weeklyTableData, monthlyTableData]);

  let convertedColumns = [];
  Object.keys(timeColumns).map((index) => {
    convertedColumns.push(timeColumns[index]);
  });

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      {opNames && (
        <BasicTable stickyHeader aria-label="sticky table" options={options}>
          <TableHead>
            <TableRow>
              {[{ isEmpty: true }, ...yearGroups].map(
                ({ isEmpty, mentionedYear, yearCount }, i) => (
                  <TableCell
                    key={isEmpty ? i : mentionedYear}
                    align="center"
                    colSpan={isEmpty ? 1 : yearCount}>
                    {mentionedYear ?? ''}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              {convertedColumns.length > 0 &&
                ['Operator', ...convertedColumns].map((val) => {
                  return (
                    <TableCell key={val} align="center" colSpan={1}>
                      {val}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              ...Object.keys(opNames).map((operatorId) => {
                const { operatorName, data } = opNames[operatorId];

                return (
                  <TableRow key={operatorId}>
                    <TableCell colSpan={1} align="center">
                      {operatorName}
                    </TableCell>
                    {Object.keys(data).map((key) => (
                      <TableCell colSpan={1} align="center" key={`${key}_${operatorId}`}>
                        {data[key]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ]}
          </TableBody>
        </BasicTable>
      )}
    </TableContainer>
  );
}

CanalTransitsTableOperator.propTypes = {
  canalTransits: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  timeFrame: PropTypes.string,
  fileName: PropTypes.string
};

export default CanalTransitsTableOperator;
