import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, CircularProgress, TextField } from '@esgian/esgianui';
import { useTheme } from '@hooks';

function PortSelect({
  getOptionDisabled,
  label,
  placeholder,
  portList,
  selectedPort,
  handleChange,
  id,
  disablePortal = true,
  renderOption,
  getOptionLabel
}) {
  const { customScrollbar } = useTheme();

  return (
    <Autocomplete
      id={id}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      fullWidth
      onChange={(_, operator) => handleChange(operator)}
      disableClearable
      ListboxProps={{ sx: { ...customScrollbar } }}
      disablePortal={disablePortal}
      disabled={!portList?.length}
      renderOption={(props, option) => {
        return renderOption ? (
          renderOption(props, option)
        ) : (
          <li {...props} key={option.id}>
            {`${option.portName}(${option.unlocode})`}
          </li>
        );
      }}
      filterOptions={(option, state) => {
        return portList?.filter(
          ({ unlocode, portName, id }) =>
            unlocode?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            portName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            id?.toString() === state.inputValue
        );
      }}
      getOptionLabel={(option) =>
        getOptionLabel ? getOptionLabel(option) : `${option.portName}(${option.unlocode})`
      }
      value={selectedPort}
      options={portList || []}
      renderInput={(params) => (
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ minWidth: '200px' }}
          placeholder={placeholder}
          fullWidth
          {...params}
          label={portList?.length ? label : <CircularProgress size={25} />}
        />
      )}
    />
  );
}

PortSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  portList: PropTypes.arrayOf(PropTypes.object),
  selectedPort: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  getOptionDisabled: PropTypes.func,
  id: PropTypes.string,
  disablePortal: PropTypes.bool,
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func
};

PortSelect.defaultProps = {
  selectedPort: {},
  portList: [],
  placeholder: '',
  label: '',
  id: 'port-select',
  getOptionDisabled: undefined
};

export default PortSelect;
