import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, NavigationControl } from 'mapbox-gl';
import { useNavigate, useParams } from 'react-router-dom';
import { MAP_STYLE_SATELLITE_MODE, SEGMENT } from '@constants';
import AnimatedPopup from 'mapbox-gl-animated-popup';
import { CircularProgress, Stack, Typography } from '@esgian/esgianui';
import { drawRegionPolygon } from '@helpers/mapHelpers';
import { useSelector } from 'react-redux';
import { getLookupRegions, getThemeMode } from '@store/features';
import { useSegment, useTheme } from '@hooks';
import useMapFullscreen from '@hooks/useMapFullscreen';
import MapFullscreenButton, {
  mapFullscreenButtonParentStyle
} from '@components/Common/MapFullscreenButton';

const createMarker = (map, portData, theme, navigate, segment) => {
  if (map?.getLayer('circle')) {
    map.removeLayer('circle');
  }
  if (map?.getSource('waypoints')) {
    map.removeSource('waypoints');
  }
  let latAvg = 0;
  let lonAvg = 0;
  portData.forEach(({ cpointlat, cpointlon, portName, id }) => {
    latAvg += cpointlat;
    lonAvg += cpointlon;
    const popup = new AnimatedPopup({
      closeButton: false,
      closeOnClick: false,
      openingAnimation: {
        duration: 500,
        easing: 'easeOutElastic',
        transform: 'scale'
      },
      closingAnimation: {
        duration: 200,
        easing: 'easeInBack',
        transform: 'scale'
      }
    });
    const description = `
    <div style="
        display: flex; 
        flex-direction: column; 
        ${
          theme.mode === 'dark'
            ? 'background: rgba(11,26,46,0.8)'
            : 'background: rgba(255, 255, 255, 0.8)'
        }"> 
        <div style="padding: 8px">
            <div style="padding-bottom: 5px;">
                <span style="font-family: 'Nato Sans', sans-serif;font-size: 12px">${portName}</span>
            </div>
        </div>
    </div>`;

    const markerEl = document.createElement('div');
    markerEl.className = 'pin-port';
    markerEl.style.backgroundImage = "url('/assets/images/marker-port.svg')";
    markerEl.style.width = '2em';
    markerEl.style.height = '2.5em';
    const marker = new Marker(markerEl)
      .setPopup(popup.setHTML(description).setLngLat([cpointlon, cpointlat]))
      .setLngLat([cpointlon, cpointlat])
      .addTo(map);
    const markerDiv = marker.getElement();
    let path = segment.id === SEGMENT.RoRo.id ? 'roro' : 'mpp';
    markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
    markerDiv.addEventListener('click', () => navigate(`/${path}/commercial-analytics/port/${id}`));
    markerDiv.addEventListener('mouseleave', () => marker.togglePopup());
  });

  latAvg = latAvg / portData.length;
  lonAvg = lonAvg / portData.length;
  map.flyTo({ center: [lonAvg, latAvg], zoom: 2.5, duration: 0 });
};

function RegionMap({ portData, loading, height }) {
  const mapContainer = useRef(null);
  const params = useParams();
  const map = useRef(null);
  const navigate = useNavigate();
  const lookupRegions = useSelector(getLookupRegions);
  const { theme } = useTheme();
  const { segment } = useSegment();
  const themeMode = useSelector(getThemeMode);
  const { isFullscreen, toggleFullscreen } = useMapFullscreen();

  useEffect(() => {
    if (!portData || !lookupRegions?.length) return; // initialize map only once
    const region = lookupRegions?.find(({ regionId }) => regionId === parseInt(params.regionId));
    const { polygon, long, lat } = region;
    map.current = new Map({
      container: mapContainer.current,
      style: MAP_STYLE_SATELLITE_MODE,
      center: [long, lat],
      projection: { name: 'mercator' },
      zoom: 2
    });
    map.current.addControl(new NavigationControl());
    map.current.on('load', () => {
      if (map.current) {
        createMarker(map.current, portData, theme, navigate, segment);
        if (region) {
          drawRegionPolygon(map.current, polygon);
        }
      }
    });

    return () => {
      map.current = null;
    };
  }, [theme, portData, params, lookupRegions, isFullscreen]);

  return (
    <div
      ref={mapContainer}
      style={
        isFullscreen
          ? {
              height: '100vh',
              width: '100vw'
            }
          : {
              height: height,
              width: '100%'
            }
      }
      className="region-map">
      {loading && (
        <div
          style={{
            height: '100%',
            width: '100%',
            background: 'rgb(255 255 255 / 50%)',
            zIndex: 2,
            position: 'absolute'
          }}>
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        </div>
      )}
      {isFullscreen && (
        <div
          id="region-map-legend"
          className="legend-region-map"
          style={{
            backgroundColor: themeMode ? 'rgba(11,26,46,0.8)' : 'rgba(255, 255, 255, 0.8)',
            position: 'absolute',
            left: '5px',
            top: '5px'
          }}>
          <Stack sx={{ pl: 6, pr: 6, pb: 2, pt: 2 }} spacing={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <span>
                <img
                  src="/assets/images/marker-port.svg"
                  alt="port icon"
                  style={{ width: '20px', height: '20px' }}
                />
              </span>
              <Typography variant={'body2'}>{`Ports`}</Typography>
            </Stack>
          </Stack>
        </div>
      )}
      <div style={mapFullscreenButtonParentStyle}>
        <MapFullscreenButton
          onClick={() => toggleFullscreen(mapContainer.current)}
          isFullScreen={isFullscreen}
        />
      </div>
    </div>
  );
}

RegionMap.propTypes = {
  portData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  height: PropTypes.string
};

RegionMap.defaultProps = {
  portData: null,
  loading: false,
  height: '40vh'
};

export default RegionMap;
