import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Paper, ShipsIcon, InfoBox, Tabs, Tab, Slide } from '@esgian/esgianui';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import OperatorTypeSelect from '@components/Inputs/OperatorTypeSelect/OperatorTypeSelect';
import { DEFAULT_NUM_PORT_CALLS } from '@constants';
import { TextWithTooltipIcon } from '@components';
import { useSegment } from '@hooks';
import { CommercialCategoryTypeSelect } from '@components/Inputs';
import CanalOverviewSection from '@components/Sections/ShipAnalytics/CanalProfileSection/SubSections/CanalOverviewSection';
import CanalStatisticsSection from '@components/Sections/ShipAnalytics/CanalProfileSection/SubSections/CanalStatisticsSection';
import { PortWaitingTimeSection } from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections';
import { getCanalArrivals, getCanalDepartures, getCanalInTransit } from '@api';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useQuery } from '@tanstack/react-query';
import CanalOperatorStatisticsSection from './SubSections/CanalOperatorStatisticsSection/CanalOperatorStatisticsSection';

function CanalProfileSection({
  canalStats = [],
  drawPolygon = '',
  canalLat = 0,
  canalLong = 0,
  canalCode = '',
  canalName = ''
}) {
  const [section, setSection] = useState(2);
  const [searchParams, setSearchParams] = useSearchParams();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const [canalDeparturesData, setCanalDeparturesData] = useState([]);
  const [canalArrivalsData, setCanalArrivalsData] = useState([]);
  const [canalInTransitData, setCanalInTransitData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isRoRo } = useSegment();
  const params = useParams();

  const { segment } = useSegment();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getCanalData = async (canalId) => {
      try {
        setLoading(true);
        await Promise.all([
          getCanalDepartures(
            {
              start: moment().subtract(3, 'months').toJSON(),
              end: null,
              nMostRecent: DEFAULT_NUM_PORT_CALLS,
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType,
              canalIds: [canalId]
            },
            signal
          )
            .then((result) => {
              setCanalDeparturesData(result);
            })
            .catch(() => {
              setCanalDeparturesData([]);
            }),
          getCanalArrivals(
            {
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType,
              canalIds: [canalId]
            },
            signal
          )
            .then((result) => {
              setCanalArrivalsData(result);
            })
            .catch(() => {
              setCanalArrivalsData([]);
            }),
          getCanalInTransit(
            {
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType,
              canalIds: [canalId]
            },
            signal
          )
            .then((result) => {
              setCanalInTransitData(result);
            })
            .catch(() => {
              setCanalInTransitData([]);
            })
        ]);
      } catch (error) {
        console.error('Error fetching canal data:', error);
      }
      setLoading(false);
    };

    getCanalData(params.canalId);

    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [operatorType, commercialCategoryType, segment]);

  const handleSectionChange = useCallback(
    (_, val) => {
      if (val === 2 && !canalStats) {
        return;
      }
      searchParams.set('section', val);
      setSearchParams(searchParams);
      setSection(val);
    },
    [canalStats]
  );

  useEffect(() => {
    setSection(parseInt(searchParams.get('section') || 1));
    return () => {
      searchParams.delete('section');
    };
  }, []);

  const allYTDQuery = useQuery({
    queryKey: [
      'canalOverview',
      'ytd',
      operatorType,
      segment.id,
      commercialCategoryType,
      params.canalId
    ],
    enabled: true,
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      let body = {
        start: moment().startOf('year').subtract(1, 'year').toJSON(),
        end: null,
        operatorTypeId: queryKey[2],
        segmentTypeId: queryKey[3],
        commercialCategoryId: queryKey[4],
        canalIds: [queryKey[5]]
      };
      return getCanalDepartures(body, signal)
        .then((results) => results)
        .catch(() => []);
    }
  });

  let ytd = 0;
  let prevYearPortCallsYTD = 0;
  let lastYearPortCalls = 0;
  if (allYTDQuery.data) {
    allYTDQuery.data.forEach((canalData) => {
      let parsedArrival = JSON.stringify(canalData.canalArrival);
      parsedArrival = JSON.parse(parsedArrival);
      if (
        new Date(parsedArrival) <= moment() &&
        new Date(parsedArrival) >= moment().startOf('year')
      ) {
        ytd++;
      }
      if (
        new Date(parsedArrival) <= moment().subtract(1, 'year') &&
        new Date(parsedArrival) >= moment().startOf('year').subtract(1, 'year')
      ) {
        prevYearPortCallsYTD++;
      }
      if (
        new Date(parsedArrival) <= moment().endOf('year').subtract(1, 'year') &&
        new Date(parsedArrival) >= moment().startOf('year').subtract(1, 'year')
      ) {
        lastYearPortCalls++;
      }
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>
      {(canalStats || loading) && (
        <>
          <Grid item xs={4} id="canal-transits-ytd">
            <InfoBox
              loading={loading}
              mainValue={ytd || 0}
              icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
              header={'Canal Transits (YTD)'}
              colorSecondary={'#63d5fa'}
              colorMain={'#61efbf'}
              headerColor={'#63d5fa'}
            />
          </Grid>
          <Grid item xs={4} id={'port-call-prev-year-ytd'}>
            <InfoBox
              loading={loading}
              mainValue={prevYearPortCallsYTD || 0}
              icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
              header={`Canal Transits (${moment().subtract(12, 'months').format('yyyy')} YTD)`}
              colorSecondary={'#57ed80'}
              colorMain={'#57edd1'}
              headerColor={'#57ed80'}
            />
          </Grid>
          <Grid item xs={4} id={'number-of-port-calls'}>
            <InfoBox
              loading={loading}
              mainValue={lastYearPortCalls || 0}
              icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
              header={`Canal Transits (${moment().subtract(12, 'months').format('yyyy')})`}
              colorSecondary={'#66a6ff'}
              colorMain={'#89f7fe'}
              headerColor={'#66a6ff'}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} id={'canal-profile-overview'}>
        <Paper>
          <Tabs value={section} onChange={handleSectionChange} variant="scrollable">
            <Tab value={1} label="Overview" />
            <Tab
              value={2}
              sx={{ cursor: canalStats ? 'pointer' : 'default' }}
              disableRipple={!canalStats}
              label={
                !canalStats && !loading ? (
                  <TextWithTooltipIcon
                    color={'text.secondary'}
                    label={'CANAL STATISTICS'}
                    tooltipText="Insufficient data to compute emissions"
                  />
                ) : (
                  'CANAL STATISTICS'
                )
              }
            />
            <Tab value={3} label="Canal Waiting Time" />
            <Tab value={4} label="Operator Statistics" />
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <>
          <Slide direction="right" in={section === 1} mountOnEnter unmountOnExit>
            <div>
              <CanalOverviewSection
                loading={loading}
                canalDeparturesData={canalDeparturesData}
                canalArrivalsData={canalArrivalsData}
                canalInTransitData={canalInTransitData}
                drawPolygon={drawPolygon}
                canalLat={canalLat}
                canalLong={canalLong}
                canalCode={canalCode}
                canalName={canalName}
              />
            </div>
          </Slide>
          <Slide direction="right" in={section === 2} mountOnEnter unmountOnExit>
            <div>
              <CanalStatisticsSection />
            </div>
          </Slide>
          {
            <Slide direction="right" in={section === 3} mountOnEnter unmountOnExit>
              <div>
                <PortWaitingTimeSection
                  assetLevel={'canal'}
                  portCongestionData={[]}
                  loading={loading}
                  canalName={canalName}
                />
              </div>
            </Slide>
          }
          <Slide direction="right" in={section === 4} mountOnEnter unmountOnExit>
            <div>
              <CanalOperatorStatisticsSection />
            </div>
          </Slide>
        </>
      </Grid>
    </Grid>
  );
}

CanalProfileSection.propTypes = {
  canalStats: PropTypes.arrayOf(PropTypes.object),
  selectedYear: PropTypes.number,
  drawPolygon: PropTypes.string,
  canalLat: PropTypes.number,
  canalLong: PropTypes.number,
  canalCode: PropTypes.string,
  canalName: PropTypes.string
};

export default CanalProfileSection;
